<template>
  <b-container fluid>
    <b-row class="justify-content-center  my-2">
      <b-card class="custom-card flex-grow-1 my-4 p-5 box-termo">
        <h5><strong>O que é o auxílio funeral? </strong></h5>
        <h5 class="mt-5">
          O Auxílio Funeral é um benefício pecuniário garantido aos sucessores
          legítimos ou testamentários de médico cooperado falecido, desde que o
          médico seja cooperado por tempo superior à seis meses.
        </h5>
        <h5 class="mt-5">
          O Auxílio Funeral será formado através de um desconto (valor de uma
          consulta) na produção de todos os cooperados com produção no mês
          subsequente ao falecimento.
        </h5>
        <h5 class="mt-5">
          <strong>
            O valor correspondente ao auxílio será descontado dos cooperados, da
            seguinte forma:
          </strong>
          Cooperado Pessoa Física ou Jurídica: O valor do desconto para
          somatória do benefício será o valor bruto de uma consulta médica por
          cooperado com produção. Caso o cooperado apresente uma produção
          inferior ao valor de uma consulta, o desconto será equivalente ao
          total líquido da produção apresentada. Caso ocorra o falecimento de
          dois ou mais médicos cooperados em uma mesma competência, será
          realizado apenas um desconto respeitando a data de solicitação, os
          descontos subsequentes serão realizados sucessivamente nos próximos
          meses.
        </h5>
        <h5 class="mt-5"><strong> Como solicitar: </strong></h5>
        <h5>
          A soma dos descontos, correspondente ao auxílio será provisionado em
          conta específica pela contabilidade da Cooperativa, sendo que o
          pagamento somente será realizado mediante:
        </h5>
        <h5>
          Solicitação formal e apresentação da documentação mínima exigida, no
          prazo máximo de 30 (trinta) dias contados da data do falecimento,
          devendo, obrigatoriamente, apresentar:
        </h5>
        <h5 class="mt-5"><strong>Certidão de óbito; </strong></h5>
        <h5><strong>Prova do vínculo sucessório; </strong></h5>
        <h5>
          <strong
            >Declaração dos herdeiros, com firma reconhecida, eximindo a
            responsabilidade da UGF no aparecimento de sucessores;
          </strong>
        </h5>
        <h5>
          <strong
            >Declaração do(s) sucessor(es) legal(is), com firma reconhecida,
            autorizando o recebimento do Auxílio Funeral;
          </strong>
        </h5>
        <h5><strong>Outro documento que se mostre necessário.</strong></h5>
        <h5 class="mt-5">
          Antes de concluída a análise do pedido, poderão os sucessores
          requerentes solicitar o adiantamento de até 30% (trinta por cento) do
          valor total do Auxílio Funeral, desde que apresentem os documentos
          comprobatórios necessários, recibos e/ou das notas fiscais.
        </h5>
        <h5>
          Após análise e aprovação, o valor será pago em uma única parcela,
          através de transferência bancária a conta corrente indicada pelos
          sucessores requerentes, sendo efetuados os devidos descontos legais,
          bem como eventuais débitos que o médico falecido tiver com a
          Cooperativa.
        </h5>
        <h5>
          O Auxílio Funeral não será pago nos casos de falecimento de médico
          cooperado eliminado ou que tenha solicitado a sua demissão da
          Cooperativa.
        </h5>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "funeral-informations",
};
</script>

<style lang="scss" scoped>
.marker {
  text-align: left;
  padding: 1rem;
}

.box-termo {
  padding: 1rem;
  max-height: 45rem;
  min-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
